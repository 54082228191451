// packages
import * as React from "react";
// components
import MainLayout from "../components/MainLayout";
import Seo from "../components/Seo";
import { MainHeading, LeadingParagraph, ParagraphContainer } from "../components/Typography";

const AboutBlackYeti = () => (
  <MainLayout>
    <Seo title="about black yeti" />
    <MainHeading>About Black Yeti</MainHeading>
    <ParagraphContainer>
      <LeadingParagraph>
        We are a board game studio and publisher run out of Minneapolis-St. Paul, MN and
        Jacksonville, FL home offices. Our aim is to appeal to new and experienced gamers alike with
        high quality products that offer unique and replayable experiences across a variety of
        genres. With formative years spanning grand strategy games, German trick-takers, CCGs,
        miniature war games, and RPGs, Black Yeti blends the best aspects of gaming into our designs
        to honor traditions while breaking new ground.
      </LeadingParagraph>
    </ParagraphContainer>
  </MainLayout>
);

export default AboutBlackYeti;
